<template>

  <body>
    <h2 class="centralizado">Clique em Pesquisar para iniciar!</h2>
      <div class="alinha">
        <img
          class="pesquisa"
          src="https://impa.br/wp-content/uploads/2018/06/pesquisas-eleitorais-1200x637.png"
          alt="pesquisa"
        />
      </div>
  </body>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.alinha {
  padding-left: 250px;
}
.pesquisa {
  width: 70%;
  height: 70%;
}
</style>